import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

import callApi from '../../services/api';
import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import MenuBar from '../home/MenuBar';
import Footer from '../home/v3/Footer';
import MobileApps from '../home/v3/MobileApps';
import Spinner from './Spinner';
import { mobileThresholdPixels, OrderSummaryText } from '../home/v2/styledComponents';
import {
  Title2 as Title2V3, Container, Content as ContentV3, Button as ButtonV3,
} from '../home/v3/styledComponents';

const Content = styled(ContentV3)`
@media (max-width: ${mobileThresholdPixels}) {
  box-sizing: border-box;
  padding: 0px 18px;
}
`;

const PaymentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 980px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 58px 140px 50px;
  margin: 135px 0;
  border-top: 8px solid ${({ theme }) => theme.colors.yellow};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 48px 25px 40px;
  }
`;

const Title2 = styled(Title2V3)`
  @media (max-width: ${mobileThresholdPixels}) {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 27px;
}
`;

const TextContainer = styled(OrderSummaryText)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  text-align: center;
  line-height: 27px;
  max-width: 570px;
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const MobileAppsContainer = styled.div`
  margin: 32px 0 25px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0 10px;
  }
  `;

const TextLink = styled(TextContainer)`
  text-decoration: underline;
  font-weight: 700;
`;

const Button = styled(ButtonV3)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  margin-top: 40px;
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: 140px;
    font-size: 14px;
    padding: 10px;
  }
`;

const checkPreAuthStatus = (transactionId, setIsLoading, setIsSuccess) => {
  callApi('/public/saveCardToken/', 'post', { payInId: transactionId })
    .then((res) => {
      setIsLoading(false);
      if (res.status !== 'SUCCEEDED') {
        setIsSuccess(false);
      }
    })
    .catch(() => {
      setIsLoading(false);
      setIsSuccess(false);
    });
};

const renderSuccess = (isPayIn) => (
  <>
    <Title2 marginBottom={40}>
      {isPayIn
        ? 'Le paiement a bien été enregistré'
        : 'Votre carte bancaire a bien été enregistrée'}
    </Title2>
    <TextContainer>
      Pour suivre votre commande ou parrainer vos amis, vous pouvez vous connecter sur notre application :
    </TextContainer>
    <MobileAppsContainer>
      <MobileApps smallTopMargin />
    </MobileAppsContainer>
    <Link to="/" style={{ flex: 1, textAlign: 'center' }}>
      <TextLink>Retourner à la page d’accueil</TextLink>
    </Link>
  </>
);

const renderFailedAttempt = (orderId, customerId) => (
  <>
    <Title2 marginBottom={40}>Transaction échouée</Title2>
    <TextContainer>
      Merci de bien vouloir ressaisir vos coordonnées bancaires car l’enregistrement n’a pas été pris en compte.
    </TextContainer>
    <Link to={orderId ? `/enregistrer-votre-moyen-de-paiement/${orderId}/${customerId}` : '/mon-compte/mes-adresses-et-paiements/'}>
      <Button navy>Réessayer</Button>
    </Link>
  </>
);

const Success = ({ orderContext: { initialize, orderId }, customerContext: { customer, setCustomerFromSessionStorage }, isPayIn }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const transactionId = urlParams.get('transactionId');

    if (transactionId) {
      if (!customer) { setCustomerFromSessionStorage(); }
      checkPreAuthStatus(transactionId, setIsLoading, setIsSuccess);
    } else { setIsLoading(false); }
    return () => {
      if (isSuccess) {
        initialize();
      }
    };
  }, []);

  return (
    <Container>
      <MenuBar />
      <Content>
        <PaymentContainer>
          {isLoading ? <Spinner /> : (
            <>
              {isSuccess ? renderSuccess(isPayIn) : renderFailedAttempt(orderId, customer?._id)}
            </>
          )}
        </PaymentContainer>
      </Content>
      <Footer />
    </Container>
  );
};

Success.propTypes = {
  orderContext: PropTypes.shape({
    initialize: PropTypes.func,
    orderId: PropTypes.string,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      _id: PropTypes.string,
    }),
    setCustomerFromSessionStorage: PropTypes.func,
  }).isRequired,
  isPayIn: PropTypes.bool,
};

Success.defaultProps = {
  isPayIn: false,
};

export default withCustomerContext(withOrderContext(Success));
